
<template>
  <p class="p2" :endTime="endTime"  :endText="endText">
    <i class="time-counter-font" :style="{  fontSize: size}" >{{days}}</i><span style="color: black">天</span>
    <i class="time-counter-font" :style="{  fontSize: size}" >{{hours}}</i><span style="color: black">时</span>
    <i class="time-counter-font" :style="{  fontSize: size}" >{{minutes}}</i> <span style="color: black">分</span>
    <i class="time-counter-font" :style="{  fontSize: size}" >{{seconds}}</i> <span style="color: black">秒</span>
  </p>
</template>
<script>
export default {
  data(){
    return {
      days:0,
      hours:0,
      minutes:0,
      seconds:0,
      pageTimer:{}
    }
  },
  props:{
    size:{
      type: String,
      default :'33px'
    },
    endTime:{
      type: String,
      default :'0'
    },
    endText:{
      type : String,
      default:'已结束'
    },
  },
  mounted () {
    this.countDowm(this.endTime);

  },
  watch:{
    endTime(curVal,oldVal){
      if(this.pageTimer){   //清除所有的定时器，防止传过来的值变化出现闪烁的问题
        for(let each in this.pageTimer){
          clearInterval(this.pageTimer[each]);
        }
      }
      this.countDowm(curVal,oldVal);
    }
  },
  methods: {
    countDowm(timestamp,oldtime){
      let self = this;
      this.pageTimer["timer1"] = setInterval(function(){
        let nowTime = new Date();
        let endTime = new Date(timestamp/1000);
        let t = parseInt( endTime.getTime() - nowTime.getTime() / 1000);
        if(t>0){
          let day =Math.floor(t/86400);
          t = t - day * 86400;
          let hour = Math.floor(t/3600);
          t = t - hour * 3600;
          let min=Math.floor((t/60)%60);
          let sec = t - min * 60;
          day = day < 10 ? "0" + day : day;
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          self.days = day;
          self.hours = hour;
          self.minutes = min;
          self.seconds = sec;
          let secStart = 59;
          self.pageTimer["timer2"] = setInterval(function () {
            self.milliseconds = secStart;
            secStart--;
            if (secStart < 0) {
              secStart = 59
            }
          }, 1000);
        }else{
          clearInterval(self.pageTimer["timer1"]);
          self.days = 0;
          self.hours = 0;
          self.minutes = 0;
          self.seconds = 0;
          self._callback();
        }
      },1000);
    },
    _callback(){
      if(this.callback && this.callback instanceof Function){
        this.callback(...this);
      }
    }
  }
}
</script>


<style scoped lang="scss">
.time-counter-font{
  color: white;
  padding: 0 8px 0 5px ;
  background-color: #409EFF;
  border-radius: 4px;
  margin:  5px;
}
</style>