<template>
    <div style="padding: 0;height:505px;border:1px solid #ccc;margin-top:12px;">
        <el-empty :image="require('@/assets/img/map.svg')" description="没有地图数据" v-if='!haveMap' style="height:507px;"></el-empty>
        <el-container v-if='haveMap'>
            <el-main style="padding: 0;overflow:hidden;position:relative;">
                <div id="map_container" style="height:503px;"></div>
                <el-radio-group v-model="mapMode" style="padding: 0;overflow:hidden;position:absolute;top:12px;left:12px;z-index:99999;">
                    <el-radio-button label="地图"></el-radio-button>
                    <el-radio-button label="卫星"></el-radio-button>
                </el-radio-group>
            </el-main>
            <el-aside width="180px" style="overflow:hidden;">
                <el-menu
                    @select="handleMapMenuSelect"
                    class="el-menu-vertical-demo">
                    <el-menu-item index="0">
                        <i class="el-icon-menu"></i>
                        <span slot="title">周边配套</span>
                    </el-menu-item>
                    <el-menu-item index="1">
                        <i class="el-icon-school"></i>
                        <span slot="title">教育机构</span>
                    </el-menu-item>
                    <el-menu-item index="2">
                        <i class="el-icon-first-aid-kit"></i>
                        <span slot="title">医疗机构</span>
                    </el-menu-item>
                    <el-menu-item index="3">
                        <i class="el-icon-goods"></i>
                        <span slot="title">商场超市</span>
                    </el-menu-item>
                    <el-menu-item index="4">
                        <i class="el-icon-fork-spoon"></i>
                        <span slot="title">餐饮娱乐</span>
                    </el-menu-item>
                    <el-menu-item index="5">
                        <i class="el-icon-truck"></i>
                        <span slot="title">交通设施</span>
                    </el-menu-item>
                    <el-menu-item index="6">
                        <i class="el-icon-guide"></i>
                        <span slot="title">公园景点</span>
                    </el-menu-item>
                    <el-menu-item index="7">
                        <i class="el-icon-bank-card"></i>
                        <span slot="title">银行金融</span>
                    </el-menu-item>
                    <el-menu-item index="8">
                        <i class="el-icon-office-building"></i>
                        <span slot="title">酒店宾馆</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
        </el-container>
    </div>
</template>
<script setup>
    import { onMounted,ref,watch,computed,nextTick } from 'vue';

    const isSearchMap = ref(false);
    const TMap = window.TMap;
    var map = {
        setCenter:(point)=>{},
    };
    var searcher = {
        searchNearBy:(keyword,point)=>{},
        explore:(params)=>{},
    };
    var marker = {
        setGeometries:(geometries)=>{},
    };
    var infoWindow = {
        open:()=>{},
        setContent:(content)=>{},
        setPosition:(point)=>{},
        close:()=>{},
    };
    var mapCenter = new TMap.LatLng(31.127455, 104.397762);

    var mapMode = ref('地图');
    var haveMap = ref(false);

    const initMap = () => {
        //定义地图中心点坐标
        //定义map变量，调用 TMap.Map() 构造函数创建地图
        map = new TMap.Map(document.getElementById('map_container'), {
            center: mapCenter,//设置地图中心点坐标
            zoom: 15,   //设置地图缩放级别
            pitch: 0,  //设置俯仰角
            rotation: 45    //设置地图旋转角度
        });
        searcher = new TMap.service.Search({ pageSize: 10 });
        marker = new TMap.MultiMarker({
            map: map,
          styles: {
            "marker": new TMap.MarkerStyle({
              "width": 25,
              "height": 35,
              "anchor": { x: 16, y: 32 },
              "src": 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png'
            })
          },
            geometries: [
            ],
        });
        //
        infoWindow = new TMap.InfoWindow({
            map: map,
            position: new TMap.LatLng(39.984104, 116.307503),
            offset: { x: 0, y: -32 } //设置信息窗相对position偏移像素
        });

        //监听标注点击事件
        marker.on("click", function (evt) {
            //设置infoWindow
            infoWindow.open(); //打开信息窗
            infoWindow.setPosition(evt.geometry.position);//设置信息窗位置
            infoWindow.setContent(
                "<div style='font-size: 14px;text-align:left;'>" +
                    "<div style='font-weight:600;text-align:left;'>" + evt.geometry.name+ '</div>'+
                    "<div style='color:#666;'>地址：" + evt.geometry.address+ '</div>'+
                "</div>"
            );//设置信息窗内容
        })
    }

    const handleMapMenuSelect = (key, keyPath) => {
            let keyword = '';

            if(key==='0' && isSearchMap.value){
                return;
            }

            switch(key){
                case '1':
                    keyword = '教育学校';
                    break;
                case '2':
                    keyword = '医疗保健';
                    break;
                case '3':
                    keyword = '购物';
                    break;
                case '4':
                    keyword = '娱乐休闲,美食';
                    break;
                case '5':
                    keyword = '交通设施';
                    break;
                case '6':
                    keyword = '旅游景点';
                    break;
                case '7':
                    keyword = '银行金融';
                    break;
                case '8':
                    keyword = '酒店宾馆';
                    break;
            }

            infoWindow.close();
            isSearchMap.value = true;
            searcher.explore({
                filter:"category="+keyword,
                center:mapCenter,
                radius:1500,
                autoExtend:false,
                servicesk:'0ww2Sw8hcLA8V7oLryzNE6sG8T8CK6X'
            }).then((res) => {
              console.log(res)
                if(res.status === 0 && res.count > 0){
                    let data = res.data.map(i=>({
                        id:i.id,
                        name:i.title,
                        address:i.address,
                        position:i.location,
                      styleId: 'marker',
                    }))
                    marker.setGeometries(data);
                }else {
                  marker.setGeometries([]);
                }
            }).finally(()=>{
                isSearchMap.value = false;
            })
        }

    const props = defineProps({
        landInfo:{}
    });

    watch(props.landInfo,(val)=>{
      // console.log(val)
        haveMap.value = !!(val.geoInfo && val.geoInfo.length > 0);
        nextTick(()=>{
            if(haveMap.value){
                initMap();
                const { geoInfo }= props.landInfo;
                if(geoInfo && geoInfo.length > 0){
                    const geos = geoInfo.map((arr) => {
                        return {
                            paths: arr.map((item) => new TMap.LatLng(item.lat, item.lng)),
                        };
                    });


                    if (geos.length > 0) {
                        mapCenter = new window.TMap.LatLng(geos[0].paths[0].lat, geos[0].paths[0].lng)
                    }
                    if(map){
                        if(map.setCenter){
                            map.setCenter(mapCenter);
                        }

                      var mark_data_arra=[];
                      mark_data_arra.push( {
                        id: "demo",
                        position:  new window.TMap.LatLng(geos[0].paths[0].lat, geos[0].paths[0].lng)

                      });
                      var markers = new window.TMap.MultiMarker({
                        id: "marker-layer1",
                        map: map,
                        geometries: mark_data_arra,
                      });

                      // 初始化polygon
                        var polygon = new TMap.MultiPolygon({
                            id: 'polygon-layer', // 图层id
                            map: map, 
                            geometries: geos,
                        });



                    }        
                }    
            }
        });
    },{ deep: true,immediate:true })

    watch(mapMode,(val)=>{
        if(val === '卫星'){
                map.setBaseMap({
                  type: 'satellite'
                });
            }else{
                map.setBaseMap({
                  type: 'vector'
                });
            }
    })
</script>